import Repo from './repos/_repo.js'

// Need to have a base URL. GLib JS refuses to work with non-URLs.
const baseUrl = window.location.protocol + "//" + window.location.host;
let currentBoard = null

export default class extends Repo {
  static project(id) {
    return `${baseUrl}/boards/${this.boardId}/projects/${id}`
  }

  static projects() {
    return `${baseUrl}/boards/${this.boardId}/projects`
  }

  static snapshots(projectId) {
    return `${baseUrl}/projects/${projectId}/snapshots`
  }

  static snapshot(projectId, id) {
    return `${baseUrl}/projects/${projectId}/snapshots/${id}`
  }

  static authorizations() {
    return `${baseUrl}/authorizations`
  }

  static web_requests() {
    return `${baseUrl}/web_requests`
  }

  static get boardId() {
    if (currentBoard) {
      return currentBoard.id
    }
    throw "Board data does not exist"
  }

  // TODO: Should go to `r.boards`
  static boardMembers() {
    if (currentBoard) {
      return currentBoard.members
    }
    throw "Board data does not exist"
  }

  static async preloadBoard(t) {
    // Always reload the board because the user might be switching to a different board.
    if (this.isInTrello()) {
      currentBoard = await t.board('id', 'members')
    } else {
      // currentBoard = { id: "default_board" }
      currentBoard = { id: "58db91817cb37afe86149ff2" }
    }

    return currentBoard
  }
}
